import theTime, { type TimeDate } from "$lib/utils/the-time";

type NewDateOptions = {
  year?: string | number;
  month?: string | number;
  day?: string | number;
  t?: string;
  hour?: string | number;
  minutes?: string | number;
  seconds?: string | number;
  milliseconds?: string | number;
  addYears?: number;
  addMonths?: number;
  addDays?: number;
  addHours?: number;
  addMinutes?: number;
  addSeconds?: number;
  addMilliseconds?: number;
};

type NewDateInit = Date | NewDateOptions | string;

/**
 * @function newDate -- DEFAULT EXPORT -- get a Date object easily customizable with options.
 * If init param is not a Date object, it will use a new Date object.
 * @param init: NewDateInit -- Date object, ISO date string, or options object for new Date object.
 * @param addOptions: NewDateOptions -- options object to add to init object.
 * @returns Date */
export const newDate = (init?: NewDateInit, addOptions?: NewDateOptions): Date => {
  const opt = init instanceof Object && !(init instanceof Date) ? init : addOptions || {};
  const date = init instanceof Date
    ? new Date(init)
    : typeof init === "string"
      ? getDateFromIsoDate(init)
      : new Date();

  // Set year, month, day
  if (typeof opt.year !== "undefined" && typeof +opt.year === "number") date.setFullYear(+opt.year);
  if (typeof opt.month !== "undefined" && typeof +opt.month === "number") date.setMonth(+opt.month - 1);
  if (typeof opt.day !== "undefined" && typeof +opt.day === "number") date.setDate(+opt.day);
  // Set by `t`, ISO time string
  if (typeof opt.t === "string") {
    const [hh, mm, ss, sss] = opt.t.split(":");
    if (typeof hh !== "undefined" && typeof +hh === "number") date.setHours(+hh);
    if (typeof mm !== "undefined" && typeof +mm === "number") date.setMinutes(+mm);
    if (typeof ss !== "undefined" && typeof +ss === "number") date.setSeconds(+ss);
    if (typeof sss !== "undefined" && typeof +sss === "number") date.setMilliseconds(+sss);
  }
  // Set hour, minute, second, millisecond
  if (typeof opt.hour !== "undefined" && typeof +opt.hour === "number") date.setHours(+opt.hour);
  if (typeof opt.minutes !== "undefined" && typeof +opt.minutes === "number") date.setMinutes(+opt.minutes);
  if (typeof opt.seconds !== "undefined" && typeof +opt.seconds === "number") date.setSeconds(+opt.seconds);
  if (typeof opt.milliseconds !== "undefined" && typeof +opt.milliseconds === "number") date.setMilliseconds(+opt.milliseconds);
  // Set by adding `years`, `months` and `days` numbers
  if (opt?.addYears) date.setFullYear(date.getFullYear() + opt.addYears);
  if (opt?.addMonths) date.setMonth(date.getMonth() + opt.addMonths);
  if (opt?.addDays) date.setDate(date.getDate() + opt.addDays);
  if (opt?.addHours) date.setHours(date.getHours() + opt.addHours);
  if (opt?.addMinutes) date.setMinutes(date.getMinutes() + opt.addMinutes);
  if (opt?.addSeconds) date.setSeconds(date.getSeconds() + opt.addSeconds);
  if (opt?.addMilliseconds) date.setMilliseconds(date.getMilliseconds() + opt.addMilliseconds);

  return date;
};

// dateString `yyyy-mm-dd`, but should support `yyyy` and `yyyy-mm`
export const getDateFromIsoDate = (dateString: string) => {
  const [year, month, day] = dateString.split("-");
  return newDate({ year, month, day });
};

/**
 * @function getIsoDate returns date portion of ISO date string, respecting timezone.
 * @param [d = new Date()]
 * @returns string of ISO date from Date -- format yyyy-mm-dd. */
export const getIsoDate = (d = new Date()) => [
  d.getFullYear(),
  String(d.getMonth() + 1).padStart(2, '0'),
  String(d.getDate()).padStart(2, '0'),
].join('-');

/* @param dateString: string - ISO date string e.g. `2025-01-10`
 * @returns boolean - true if the date is today, false otherwise
 */
export const isIsoDateToday = (dateString: string) =>
  dateString === getIsoDate();

// getHoursOfUTCDateTimeStringFromDateDay
// get a list of all hours of day (local date) in UTC date time string
export const getHoursOfUTCDateTimeStringFromDateDay = (forDate = new Date()) => {
  const dateHHInit = theTime(forDate, { T: 0 });
  // Recursively get all hours from Date
  const getAllHours = (
    d: TimeDate,
    acc: string[] = []
  ): string[] => {
    const next = d.goes({ HH: 1 });
    acc.push(d.toISOString());
    return next.getDate() === d.getDate() ? getAllHours(next, acc) : acc;
  };

  return getAllHours(dateHHInit);
};

export default newDate;
